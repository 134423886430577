<template>
    <div v-if="groupAccounts.length">
        <styled-tooltip
            position="top">
            <template #content>
                <ul>
                    <li
                        v-for="(group, index) in groupAccounts"
                        :key="index">
                        <span>{{ group.name }}</span>
                    </li>
                </ul>
            </template>
            <span class="text-link">
                {{ groupAccounts.length }}
            </span>
        </styled-tooltip>
    </div>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip';

export default {
    components: {
        StyledTooltip
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        groupAccounts() {
            return this.item.dealers?.data ?? [];
        }
    }
};
</script>
